import * as React from 'react';
import { Link } from 'react-router-dom';
import CommonMethods from '../../utils/CommonMethods';
import './SubjectList.scss'
import APP_CONST from '../../constants/APP_CONST';

export function SubjectList({ subjects, categoryId, gradeId, subjectId, onClick }) {
  return (
    <ul className={`subject-list  ${APP_CONST.SECTIONS.SUBJECTS_SECTION}`}>
      {subjects.map((subject, index) => <li
        key={subject.subjectName}
      >
        <Link
          onClick={() => { onClick(subject) }}
          className={`subject ${CommonMethods.labelToId(subject.subjectName) === subjectId ? 'active' : ''} ${CommonMethods.getJsUIClass(index)}`}
          to={`/videos?categoryId=${categoryId}&gradeId=${gradeId}&subjectId=${CommonMethods.labelToId(subject.subjectName)}`}>
          {subject.subjectName}

        </Link>
      </li>)}
    </ul>
  );
};