import React from 'react';
import { Link } from 'react-router-dom';
import CommonMethods from '../../utils/CommonMethods';
import './GradeList.scss'
import APP_CONST from '../../constants/APP_CONST';

GradeList.propTypes = {

};

export default function GradeList({ grades, onClick, categoryId, gradeId }) {
  return (
    <ul className={`grade-list scroll-x ${APP_CONST.SECTIONS.GRADES_SECTION}`}>
      {grades.map((grade, index) => <li
        key={grade.gradeName}
      >
        <Link
          onClick={() => { onClick(grade) }}
          className={`grade zoom-effect ${CommonMethods.labelToId(grade.gradeName) === gradeId ? 'active' : ''} ${CommonMethods.getJsUIClass(index)}`}
          to={`/videos?categoryId=${categoryId}&gradeId=${gradeId}`}>
          {grade.gradeName}

        </Link>
      </li>)}
    </ul>
  );
}
