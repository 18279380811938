import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { LeftArrowIcon } from '../../components/Icons/Icons'
import './VideoListPage.scss'
import CommonMethods from '../../utils/CommonMethods'
import json from '../../json/tvapp.json'
import VideoList from '../../components/VideoList/VideoList'
import { SubjectList } from '../../components/SubjectList/SubjectList'
import GradeList from '../../components/GradeList/GradeList'
import KEY_MAPPING_CONST from '../../constants/KEY_MAPPING_CONST'
import APP_CONST from '../../constants/APP_CONST'

const {
    VK_BACK_SPACE,
    VK_ENTER,
    VK_LEFT,
    VK_RIGHT,
    VK_UP,
    VK_DOWN,
} = KEY_MAPPING_CONST

const { GRADES_SECTION, SUBJECTS_SECTION, VIDEOS_SECTION } = APP_CONST.SECTIONS


export default class CategoryDetailsPage extends Component {
    categoryId = CommonMethods.getParameterByName('categoryId')
    gradeId = CommonMethods.getParameterByName('gradeId')
    subjectId = CommonMethods.getParameterByName('subjectId')
    videoId = CommonMethods.getParameterByName('videoId')
    subjectSectionRef = React.createRef()

    categoryObj = json.find(category => CommonMethods.labelToId(category.categoryName) === this.categoryId)

    isGradePresent = !!this.categoryObj.grades;

    getDefaultActiveGrade() {
        if (!this.isGradePresent) return null
        return this.categoryObj.grades.find(grade => CommonMethods.labelToId(grade.gradeName) === this.gradeId) || this.categoryObj.grades[0]
    }

    getDefaultActiveSubject() {
        if (this.isGradePresent) {
            return this.getDefaultActiveGrade().subjects.find(subject => CommonMethods.labelToId(subject.subjectName) === this.subjectId) || this.getDefaultActiveGrade().subjects[0]
        }
        return this.categoryObj.subjects.find(subject => CommonMethods.labelToId(subject.subjectName) === this.subjectId) || this.categoryObj.subjects[0]
    }

    state = {
        focusSection: this.isGradePresent ? GRADES_SECTION : SUBJECTS_SECTION,
        focusElementIndex: 0,
        activeGrade: this.getDefaultActiveGrade(),
        activeSubject: this.getDefaultActiveSubject(),
    }

    handleGrade = (grade) => {
        this.setState({
            activeGrade: grade,
            activeSubject: grade.subjects[0],
        })
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleRemoteKey, false);
        if (this.videoId) {
            const videoIndex = this.state.activeSubject.videos.findIndex(video => CommonMethods.labelToId(video.name) === this.videoId)
            // const elmnt = document.querySelector(`.${VIDEOS_SECTION} .${CommonMethods.getJsUIClass(focusElementIndex)}`)
            // elmnt.scrollIntoView(false); // Bottom
            this.setState({
                focusElementIndex: videoIndex,
                focusSection: VIDEOS_SECTION
            })

        } else {
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleRemoteKey, false)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { focusSection, focusElementIndex } = this.state

        if (prevState.focusElementIndex !== focusElementIndex || prevState.focusSection !== focusSection) {
            this.getFocusedElement().focus()
            this.scrollToTop()
        }
        this.scrollLastSubject()
    }

    scrollToTop() {
        if (this.state.focusSection !== VIDEOS_SECTION && this.state.focusElementIndex < APP_CONST.NO_OF_ELEMENT_IN_ROW) {
            window.scrollTo(0, 0);
        }
    }

    scrollLastSubject() {
        const { focusSection, focusElementIndex } = this.state
        if (focusSection === SUBJECTS_SECTION && focusElementIndex === this.getSubjects().length - 1) {
            this.subjectSectionRef.current.scrollLeft += 200;
        } else if (focusSection === SUBJECTS_SECTION && focusElementIndex === 0) {
            this.subjectSectionRef.current.scrollLeft -= 200;
        }
    }

    getFocusedElement() {
        const { focusSection, focusElementIndex } = this.state
        return document.querySelector(`.${focusSection} .${CommonMethods.getJsUIClass(focusElementIndex)}`)
    }

    getFocusSectionElemnts() {
        const { focusSection, activeGrade, activeSubject } = this.state
        switch (focusSection) {
            case GRADES_SECTION:
                return this.categoryObj.grades
            case SUBJECTS_SECTION:
                return (activeGrade || this.categoryObj).subjects
            case VIDEOS_SECTION:
                return activeSubject.videos
            default:
                return []
        }
    }

    moveToLeft() {
        const focusElementIndex = this.state.focusElementIndex - APP_CONST.NEXT_ELEMENT_MOVE
        if (focusElementIndex >= 0) {
            this.setState({ focusElementIndex })
        } else {
            this.setState({ focusElementIndex: this.getFocusSectionElemnts().length - 1 })
        }
    }

    moveToRight() {
        const focusElementIndex = this.state.focusElementIndex + APP_CONST.NEXT_ELEMENT_MOVE

        if (focusElementIndex <= this.getFocusSectionElemnts().length - 1) {
            this.setState({ focusElementIndex })
        } else {
            this.setState({ focusElementIndex: 0 })
        }
    }

    moveToUp() {
        const { focusSection, focusElementIndex, activeSubject } = this.state
        let newFocusSection = focusSection, newFocusElementIndex = 0;
        switch (focusSection) {
            case SUBJECTS_SECTION:
                if (this.isGradePresent)
                    newFocusSection = GRADES_SECTION
                break
            case VIDEOS_SECTION:
                // check focus at 1st row
                if (focusElementIndex < APP_CONST.NO_OF_ELEMENT_IN_ROW) {
                    newFocusSection = SUBJECTS_SECTION
                    newFocusElementIndex = this.getSubjects().findIndex(subject => subject.subjectName === activeSubject.subjectName)
                } else
                    newFocusElementIndex = CommonMethods.getUpIndex(focusElementIndex, activeSubject.videos.length)
                break
            default:
        }

        this.setState({ focusSection: newFocusSection, focusElementIndex: newFocusElementIndex })
    }

    moveToDown() {
        const { focusSection, focusElementIndex, activeSubject } = this.state
        let newFocusSection = focusSection, newFocusElementIndex = 0;
        switch (focusSection) {
            case GRADES_SECTION:
                newFocusSection = SUBJECTS_SECTION
                break
            case SUBJECTS_SECTION:
                newFocusSection = VIDEOS_SECTION
                break
            case VIDEOS_SECTION:
                newFocusElementIndex = CommonMethods.getDownIndex(focusElementIndex, activeSubject.videos.length)
                break
            default:
        }

        this.setState({ focusSection: newFocusSection, focusElementIndex: newFocusElementIndex })
    }

    handleEnter() {
        this.getFocusedElement().click()
    }

    handleRemoteKey = (ev) => {
        switch (ev.keyCode) {
            case VK_BACK_SPACE:
                this.props.history.push(`/?categoryId=${this.categoryId}`);
                break;
            case VK_ENTER:
                this.handleEnter()
                break
            case VK_LEFT: {
                this.moveToLeft()
                break
            }
            case VK_RIGHT: {
                this.moveToRight()
                break
            }
            case VK_UP: {
                this.moveToUp()
                break
            }
            case VK_DOWN: {
                this.moveToDown()
                break
            }
            default:
        }
        // Block the browser from handling the keydown event.
        ev.preventDefault();
    }

    getSubjects() {
        return (this.state.activeGrade || this.categoryObj).subjects
    }

    render() {
        const { activeGrade, activeSubject } = this.state

        return (
            <main className="section-page">
                <section className="page-header">
                    <Link to="/" className="back-link" tabIndex={-1}>
                        <LeftArrowIcon />
                        <span className="ml-20 category-label">{CommonMethods.idToLabel(this.categoryId)}</span>
                    </Link>
                </section>


                {this.isGradePresent && <GradeList
                    grades={this.categoryObj.grades}
                    categoryId={this.categoryId}
                    gradeId={CommonMethods.labelToId(activeGrade && activeGrade.gradeName)}
                    onClick={this.handleGrade}
                />}


                <section className="scroll-x" ref={this.subjectSectionRef}>
                    <SubjectList
                        subjects={this.getSubjects()}
                        categoryId={this.categoryId}
                        gradeId={CommonMethods.labelToId(activeGrade && activeGrade.gradeName)}
                        subjectId={CommonMethods.labelToId(activeSubject.subjectName)}
                        onClick={(subject) => { this.setState({ activeSubject: subject }) }}
                    />
                </section>

                <VideoList
                    videos={activeSubject.videos}
                    categoryId={this.categoryId}
                    gradeId={CommonMethods.labelToId(activeGrade && activeGrade.gradeName)}
                    subjectId={CommonMethods.labelToId(activeSubject.subjectName)}
                />

            </main >
        )
    }
}