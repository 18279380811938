import React from "react";
import { Link } from "react-router-dom";
import CommonMethods from "../../utils/CommonMethods";
import "./VideoList.scss";
import APP_CONST from "../../constants/APP_CONST";

export default function VideoList({ videos, categoryId, gradeId, subjectId }) {
    return (
        <ul className={`video-list ${APP_CONST.SECTIONS.VIDEOS_SECTION}`}>
            {videos.map((video, index) => (
                <li key={video.name}>
                    <Link
                        to={`/video-player?categoryId=${categoryId}&gradeId=${gradeId}&subjectId=${subjectId}&videoId=${CommonMethods.labelToId(
                            video.name
                        )}`}
                        className={`video zoom-effect ${CommonMethods.getJsUIClass(
                            index
                        )}`}
                    >
                        <div
                            className="bg-img"
                            style={{
                                backgroundImage: `url(${CommonMethods.getThumbnilImage(
                                    video.thumbnail
                                )})`,
                            }}
                        ></div>
                        <div className="video-mask">
                            {/* <PlayIcon className="mb-40" /> */}
                            {subjectId !== "Squigglemajig" && (
                                <p className="videoName mb-20">{video.name}</p>
                            )}
                            {/* {video.description && <p className="videoDescription">{video.description}</p>} */}
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    );
}
