import json from "../json/tvapp.json";
import APP_CONST from "../constants/APP_CONST";

export default class CommonMethods {
    static isEmpty(value) {
        if (!value) return true;
        for (var key in value) {
            if (hasOwnProperty.call(value, key)) {
                return false;
            }
        }
        return true;
    }

    static labelToId(label) {
        if (!label) return "";
        return label.trim().split(" ").join("-").split(".").join("_");
    }

    static idToLabel(id = "") {
        return id.split("-").join(" ").split("_").join(".");
    }

    static getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    static getVideoURL(url) {
        return `${APP_CONST.BASE_URL}/${url}`;
    }

    static getThumbnilImage(thumbnail) {
        return `${APP_CONST.BASE_URL}/Thumbnails/${thumbnail}`;
    }

    static getVideos() {
        return json.map((category) => {
            if (category.grades) {
                return category.grades.map((grade) =>
                    grade.subjects.map((subject) =>
                        subject.videos.map((video) =>
                            CommonMethods.getVideoURL(video.url)
                        )
                    )
                );
            }
            return category.subjects.map((subject) =>
                subject.videos.map((video) =>
                    CommonMethods.getVideoURL(video.url)
                )
            );
        });
    }

    static getDownIndex(index, length) {
        const newIndex = index + APP_CONST.NO_OF_ELEMENT_IN_ROW;
        if (newIndex > length - 1) {
            return length - 1;
        }
        return newIndex;
    }

    static getUpIndex(index) {
        const newIndex = index - APP_CONST.NO_OF_ELEMENT_IN_ROW;
        if (newIndex < 0) {
            return 0;
        }
        return newIndex;
    }

    static getJsUIClass(index) {
        return `js-element-${index}`;
    }
}
