import React from 'react';
import Header from '../Header/Header';


export default function RootComponent({ children }) {
  // console.log(JSON.stringify(CommonMethods.getVideos()))

  return (
    <>
      <Header />
      {children}
    </>
  );
}


