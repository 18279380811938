import React, { Fragment } from 'react'
import './Icons.scss'
import bigSchoolImg from '../../images/big-school.png'
import hoverBigSchoolImg from '../../images/selected-big-school.png'


export function BookmarkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={65} height={82} viewBox="0 0 65 82">
      <path fill="#EF8126" fillRule="nonzero" d="M62.736 0H2.263C1.013 0 0 .907 0 2.027v75.916c-.002 1.521.947 2.915 2.456 3.61 1.51.694 3.328.573 4.706-.315L32.5 64.968l25.338 16.27c1.379.886 3.194 1.006 4.704.312C64.05 80.855 65 79.462 65 77.943V2.027C65 .907 63.986 0 62.736 0zM60 77L33.796 60.32a2.456 2.456 0 0 0-2.592 0L5 77V5h55v72z" />
    </svg>
  )
}

export function HatIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={168} height={108} viewBox="0 0 168 108">
      <g fill="none" fillRule="evenodd" stroke="#13A54D" strokeWidth={6} transform="translate(0 -46)">
        <path d="M36.308 97.532l.435 53.066 92.024-.47V99.315l-46.56 21.635-45.9-23.418z" />
        <path d="M7.343 80.985l74.64 39.789 76.37-35.67L84.17 49.888 7.343 80.985z" />
        <path strokeLinecap="square" d="M155.267 84.87v28.743" />
        <circle cx="156.767" cy="121.113" r="7.5" />
      </g>
    </svg>

  )
}

export function DocumentIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={92} height={124} viewBox="0 0 92 124">
      <g fill="none" fillRule="evenodd">
        <path fill="#420085" fillRule="nonzero" d="M92 2.296v119.407A2.298 2.298 0 0 1 89.7 124H2.3c-1.27 0-2.3-1.028-2.3-2.297V25.26c0-.05.025-.094.03-.144.01-.202.05-.4.117-.59.025-.074.043-.145.076-.217.111-.25.267-.478.46-.673l23-22.962a2.23 2.23 0 0 1 .676-.46c.07-.03.14-.054.212-.076.192-.066.392-.106.595-.119.04.007.085-.018.133-.018h64.4C90.97 0 92 1.027 92 2.296zM7.852 22.963H23V7.839L7.852 22.963zM87.4 4.593H27.6v20.666a2.298 2.298 0 0 1-2.3 2.296H4.6v91.853h82.8V4.592z" />
        <g stroke="#420085" strokeLinecap="square" strokeWidth={6}>
          <path d="M37 45l13 13M37 90l4 4M50 45L37 58M55 84L42 94" />
        </g>
      </g>
    </svg>

  )
}
export function TriangleIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={79} height={100} viewBox="0 0 79 100">
      <g fill="none" fillRule="evenodd" stroke="#CA3366" strokeWidth={6}>
        <path d="M37.633 6L73 60.55 6 61z" />
        <path strokeLinecap="square" d="M38 64l1 32" />
      </g>
    </svg>

  )
}
export function GroupIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={138} height={41} viewBox="0 0 138 41">
      <g fill="none" fillRule="evenodd" stroke="#FFCA01" strokeWidth={6}>
        <path d="M3 3h50v35H3zM85 3h50v35H85z" />
        <path strokeLinecap="square" d="M56 20.5h25.536" />
      </g>
    </svg>

  )
}
export function HeartIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={95} height={84} viewBox="0 0 95 84">
      <path fill="#87BEBA" fillRule="nonzero" d="M87.327 7.798C82.352 2.795 75.767.061 68.737.061c-7.028 0-13.634 2.755-18.609 7.758l-2.598 2.613-2.638-2.654C39.917 2.775 33.292 0 26.262 0 19.255 0 12.649 2.755 7.695 7.738 2.719 12.74-.02 19.385 0 26.454c0 7.069 2.76 13.693 7.734 18.696l37.823 38.04c.523.526 1.228.81 1.913.81s1.39-.263 1.913-.79l37.903-37.98C92.261 40.229 95 33.585 95 26.516c.02-7.07-2.699-13.713-7.673-18.717zm-3.42 33.11L47.97 77 12.113 40.848C8.172 36.874 6 31.602 6 25.986c0-5.617 2.152-10.889 6.093-14.842C16.015 7.19 21.243 5 26.793 5c5.571 0 10.82 2.19 14.762 6.164l4.545 4.582a2.691 2.691 0 0 0 3.84 0l4.505-4.542c3.942-3.974 9.19-6.163 14.74-6.163 5.551 0 10.78 2.19 14.722 6.143C87.848 15.158 90 20.43 90 26.046c.02 5.617-2.152 10.889-6.093 14.863z" />
    </svg>

  )
}
export function BallIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={107} height={107} viewBox="0 0 107 107">
      <g fill="none" fillRule="evenodd" stroke="#C5D83E" strokeWidth={6} transform="translate(3 3)">
        <circle cx="50.5" cy="50.5" r="50.5" />
        <path d="M19 12c13.333 21.834 13.333 47.834 0 78M82 90c-13.333-21.834-13.333-47.834 0-78" />
      </g>
    </svg>

  )
}

export function HomeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={38} height={36} viewBox="0 0 38 36">
      <path fill="#FFF" fillRule="nonzero" d="M37.524 17.565a1.747 1.747 0 0 0-.149-2.538L20.393.486c-.771-.66-2.007-.646-2.762.031L.591 15.803a1.737 1.737 0 0 0-.082 2.533l.427.436c.709.721 1.855.807 2.558.191l1.273-1.116v16.339c0 1.002.83 1.814 1.854 1.814h6.642c1.024 0 1.854-.812 1.854-1.814v-11.43h8.472v11.43c-.014 1.002.718 1.813 1.742 1.813h7.039c1.024 0 1.854-.811 1.854-1.814V18.077l.785.675c.434.373 1.343.074 2.032-.668l.483-.52z" />
    </svg>

  )
}
export function MindsetIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={57} height={59} viewBox="0 0 57 59">
      <path fill="#FFF" fillRule="nonzero" d="M54.583 0H0v59h57V0h-2.417zM54 3v14.34c-.096 0-.191-.007-.287-.007a29.301 29.301 0 0 0-11.97 2.514 27.615 27.615 0 0 0-3.839 2.069l-.096.06A26.103 26.103 0 0 0 32.123 27c-2.27-3.18-5.213-5.63-8.831-7.35a26.564 26.564 0 0 0-5.5-1.917l-.096-.024a26.656 26.656 0 0 0-5.849-.637c-3.032 0-6.036.577-8.847 1.698V3h51zM3 36.711a10.361 10.361 0 0 1 3.863-.71c2.765 0 5.147 1.03 7.145 3.092 1.385 1.434 2.289 3.08 2.712 4.94a11.721 11.721 0 0 1 .28 2.57V55H3V36.711zm35 18.285V46.49c0-.383.018-.76.055-1.128.023-.237.056-.468.094-.702.352-2.122 1.333-3.985 2.942-5.589 2.062-2.046 4.595-3.07 7.6-3.07 1.987 0 3.754.468 5.309 1.38V55l-16-.004z" />
    </svg>

  )
}

export function LeftArrowIcon() {
  return (
    <i className="arrow-left-icon"><span className="arrow-left"></span><span className="line"></span></i>
  )
}

export function PlayIcon({ className, onClick }) {
  return (
    <i className={`play-icon ${className}`} onClick={onClick}><span className="right-trangle"></span></i>
  )
}

export function GradeSchoolIcon() {
  return (
    <Fragment>
      <img src={bigSchoolImg} alt="" className="non-hover" />
      <img src={hoverBigSchoolImg} alt="" className="hover" />
    </Fragment>
  )
}

export function QALiveIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="127px" height="96px" viewBox="0 0 127 96" version="1.1">
      <title>Icons</title>
      <desc>Created with Sketch.</desc>
      <g id="Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Group-3" transform="translate(2.000000, 1.000000)" stroke="#EF8126" strokeWidth={6}>
          <path d="M3,3 L3,72.0181267 L83.5195963,72.5061243 L102.327069,86.8161582 L101.915509,72 L120,72 L120,3 L3,3 Z" id="Rectangle" />
          <polyline id="Path-3" points="17.5 72 17.5 88.5 39 72.5" />
        </g>
      </g>
    </svg>
  )
}
