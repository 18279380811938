import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CategoriesPage.scss";
import CommonMethods from "../../utils/CommonMethods";
import KEY_MAPPING_CONST from "../../constants/KEY_MAPPING_CONST";
import Modal from "../../components/Modal/Modal";
import APP_CONST from "../../constants/APP_CONST";
import categories from "../../constants/categories";
const { VK_BACK_SPACE, VK_ENTER, VK_LEFT, VK_RIGHT, VK_UP, VK_DOWN } =
    KEY_MAPPING_CONST;

const CategoriesFn = () => {
    const [showPopup, setShowPopup] = useState(false),
        [focusElementIndex, setFocusElementIndex] = useState(0);

    const categoryId = CommonMethods.getParameterByName("categoryId");

    const focusElement = useCallback(() => {
        const element = document.querySelector(
            `.category-${focusElementIndex}`
        );
        element.focus();
    }, [focusElementIndex]);

    const scrollToTop = useCallback(() => {
        if (focusElementIndex < APP_CONST.NO_OF_ELEMENT_IN_ROW) {
            window.scrollTo(0, 0);
        }
    }, [focusElementIndex]);

    const handleRemoteKey = useCallback(
        (e) => {
            e.preventDefault();
            switch (e.keyCode) {
                case VK_BACK_SPACE:
                    if (showPopup) {
                        setShowPopup(false);
                    } else {
                        setShowPopup(true);
                    }
                    break;
                case VK_ENTER:
                    if (showPopup) {
                        setShowPopup(false);
                        window.close();
                    } else {
                        const element = document.querySelector(
                            `.category-${focusElementIndex}`
                        );
                        element.click();
                    }
                    break;
                case VK_LEFT: {
                    const newFocusElement =
                        focusElementIndex - APP_CONST.NEXT_ELEMENT_MOVE;
                    if (newFocusElement >= 0) {
                        setFocusElementIndex(newFocusElement);
                    } else {
                        setFocusElementIndex(categories.length - 1);
                    }
                    break;
                }
                case VK_RIGHT: {
                    const newFocusElement =
                        focusElementIndex + APP_CONST.NEXT_ELEMENT_MOVE;
                    if (newFocusElement <= categories.length - 1) {
                        setFocusElementIndex(newFocusElement);
                    } else {
                        setFocusElementIndex(0);
                    }
                    break;
                }
                case VK_UP: {
                    setFocusElementIndex((index) =>
                        CommonMethods.getUpIndex(index)
                    );
                    break;
                }
                case VK_DOWN: {
                    setFocusElementIndex((index) =>
                        CommonMethods.getDownIndex(index, categories.length)
                    );
                    break;
                }
                default:
            }
        },
        [focusElementIndex, showPopup]
    );

    useEffect(() => {
        focusElement();
        scrollToTop();
        document.addEventListener("keydown", handleRemoteKey, false);

        return () =>
            document.removeEventListener("keydown", handleRemoteKey, false);
    }, [focusElement, scrollToTop, handleRemoteKey]);

    useEffect(() => {
        const index = categories.findIndex(
            (category) => CommonMethods.labelToId(category.label) === categoryId
        );

        setFocusElementIndex(index === -1 ? 0 : index);
    }, [categoryId]);

    return (
        <main className="category-page">
            <ul className="category-list">
                {categories.map((category, index) => (
                    <li key={category.label}>
                        <Link
                            to={`/videos?categoryId=${CommonMethods.labelToId(
                                category.label
                            )}`}
                            className={`category zoom-effect category-${category.className} category-${index}`}
                        >
                            {<category.image />}
                            {category.label !== "Big School" && (
                                <p className="label">{category.label}</p>
                            )}
                        </Link>
                    </li>
                ))}
            </ul>

            {showPopup && (
                <Modal className="confirmation-popup">
                    <h3 className="heading">
                        Please click OK to close the app
                    </h3>
                </Modal>
            )}
        </main>
    );
};

export default CategoriesFn;
