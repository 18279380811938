
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import React from 'react';
import Root from '../components/Root/Root';
import CategoriesPage from '../pages/CategoriesPage/CategoriesPage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import CategoryDetailsPage from '../pages/CategoryDetailsPage/CategoryDetailsPage'
// import TestVideoPlayer from '../pages/VideoPlayerPage/TestVideoPlayer'
import NewVideoPlayer from '../pages/VideoPlayerPage/NewVideoPlayer'


const AppRoutes = () => (
  <BrowserRouter>
    <Root path="/">
      <Switch>
        <Route
          exact
          path="/"
          name="CategoriesPage"
          component={CategoriesPage}
        />
        <Route
          exact
          path="/videos"
          name="CategoryDetailsPage"
          component={CategoryDetailsPage}
        />

        {/* <Route
            exact
            path="/old-video-player"
            component={VideoPlayerPage}
          /> */}
        {/* <Route
          exact
          path="/test-player"
          component={TestVideoPlayer}
        /> */}

        <Route
          exact
          path="/video-player"
          component={NewVideoPlayer}
        />

        <Route
          path="*"
          component={NotFoundPage}
        />
      </Switch>
    </Root>
  </BrowserRouter>
);

export default AppRoutes;