import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'
import ReactPlayer from 'react-player'
import KEY_MAPPING_CONST from '../../constants/KEY_MAPPING_CONST'
import { Link } from 'react-router-dom'
import { PlayIcon, LeftArrowIcon } from '../../components/Icons/Icons'
import CommonMethods from '../../utils/CommonMethods'
import json from '../../json/tvapp.json'
import './VideoPlayerPage.scss'

const {
    VK_UP,
    VK_RIGHT,
    VK_DOWN,
    VK_LEFT,
    VK_ENTER,
    VK_BACK_SPACE,
    VK_PLAY,
    VK_PAUSE,
    VK_PLAY_PAUSE,
    VK_STOP,
    VK_FAST_FWD,
    VK_REWIND,

    KEY_ONE,
    KEY_THREE,
    KEY_ZERO,
    KEY_DOT,
} = KEY_MAPPING_CONST

const [VOLUME_RANGE, DEFAULT_PLAYBACK_RATE] = [0.1, 1.0]
const [START_PLAYED_VOLUME_UNIT, END_PLAYED_VOLUME_UNIT] = [0, 1]


class VideoPlayer extends Component {
    categoryId = CommonMethods.getParameterByName('categoryId')
    gradeId = CommonMethods.getParameterByName('gradeId')
    subjectId = CommonMethods.getParameterByName('subjectId')
    videoId = CommonMethods.getParameterByName('videoId')

    getVideoObject() {

        const categoryObj = json.find(category => CommonMethods.labelToId(category.categoryName) === this.categoryId)
        const isGradePresent = !!categoryObj.grades;
        let subjects = []
        if (isGradePresent) {
            subjects = categoryObj.grades.find(grade => CommonMethods.labelToId(grade.gradeName) === this.gradeId).subjects
        } else {
            subjects = categoryObj.subjects
        }
        const subjectObj = subjects.find(subject => CommonMethods.labelToId(subject.subjectName) === this.subjectId)
        return subjectObj.videos.find(video => CommonMethods.labelToId(video.name) === this.videoId)

    }

    selectedVideo = this.getVideoObject()

    state = {
        url: '',
        poster: '',
        playing: false,
        controls: true,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: DEFAULT_PLAYBACK_RATE,
        eventKeyCodes: [],
    }

    handlePlay = () => {
        console.log('handlePlay')
        this.setState({ playing: true })
    }

    handlePause = () => {
        console.log('handlePause')
        this.setState({ playing: false })
    }

    handlePlayPause = () => {
        console.log('handlePlayPause')
        this.setState({ playing: !this.state.playing })
    }

    handleStop = () => {
        console.log('handleStop')
        // old code
        // this.setState({ url: null, playing: false })
        const played = 0;
        this.setState({ playing: false, played })
        this.player.seekTo(parseFloat(played))
    }

    handleClickFullscreen = () => {
        screenfull.request(findDOMNode(this.player))
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted })
    }

    handleProgress = state => {
        console.log('onProgress', state)
        this.setState(state)
    }

    handleDuration = (duration) => {
        console.log('onDuration', duration)
        const SEC_FF_BB = 10
        const perSecPlayed = 1 / duration // 1sec unit value video played
        this.secFFBBVideo = SEC_FF_BB * perSecPlayed

    }

    ref = player => {
        this.player = player
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleRemoteKey, false);
        this.setState({
            url: CommonMethods.getVideoURL(this.selectedVideo.url),
            poster: CommonMethods.getThumbnilImage(this.selectedVideo.thumbnail),
        })
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleRemoteKey, false)
        this.setState({
            url: '',
            poster: '',
        })
    }


    handleRemoteKey = (ev) => {
        ev.preventDefault()
        this.setState({ eventKeyCodes: [...this.state.eventKeyCodes, ev.keyCode] })
        switch (ev.keyCode) {
            case VK_REWIND:
            case VK_LEFT:
                if (this.state.played > START_PLAYED_VOLUME_UNIT) {
                    let played = this.state.played - this.secFFBBVideo
                    if (played < START_PLAYED_VOLUME_UNIT) {
                        played = START_PLAYED_VOLUME_UNIT
                    }
                    this.handlePause()
                    this.player.seekTo(played)
                }
                break;
            case VK_FAST_FWD:
            case VK_RIGHT:
                if (this.state.played < END_PLAYED_VOLUME_UNIT) {
                    let played = this.state.played + this.secFFBBVideo
                    if (played > END_PLAYED_VOLUME_UNIT) {
                        played = END_PLAYED_VOLUME_UNIT
                    }
                    this.handlePause()
                    this.player.seekTo(played)
                }
                break;
            case VK_UP:
                if (this.state.volume < END_PLAYED_VOLUME_UNIT) {
                    let volume = this.state.volume + VOLUME_RANGE
                    if (volume > END_PLAYED_VOLUME_UNIT) {
                        volume = END_PLAYED_VOLUME_UNIT
                    }
                    this.setState({ volume })
                }
                break;
            case VK_DOWN:
                if (this.state.volume > START_PLAYED_VOLUME_UNIT) {
                    let volume = this.state.volume - VOLUME_RANGE
                    if (volume < START_PLAYED_VOLUME_UNIT) {
                        volume = START_PLAYED_VOLUME_UNIT
                    }
                    this.setState({ volume })
                }
                break;
            case VK_ENTER:
                if (!this.state.playing && !screenfull.isFullscreen) {
                    this.handleClickFullscreen(ev)
                }
                this.handlePlayPause()
                break;
            case VK_BACK_SPACE:
                if (screenfull.isFullscreen) {
                    screenfull.exit()
                } else {
                    this.props.history.push(`/videos?categoryId=${this.categoryId}&gradeId=${this.gradeId}&subjectId=${this.subjectId}&videoId=${this.videoId}`);
                }
                this.handlePause()
                break;
            case VK_PLAY:
            case KEY_ONE:
                this.handlePlay()
                break;
            case VK_PAUSE:
            case KEY_THREE:
                this.handlePause()
                break;
            case VK_PLAY_PAUSE:
            case KEY_DOT:
                this.handlePlayPause()
                break;
            case VK_STOP:
            case KEY_ZERO:
                this.handleStop()
                break;
            default:
        }
    }

    render() {
        const { url, playing, controls, volume, muted, playbackRate, poster } = this.state


        return (
            <main className='video-player-page'>

                <div className='player-wrapper'>
                    <ReactPlayer
                        ref={this.ref}
                        className='react-player'
                        width='100%'
                        height='100%'
                        loop={true}
                        url={url}
                        playing={playing}
                        controls={controls}
                        playbackRate={playbackRate}
                        volume={volume}
                        muted={muted}
                        onReady={() => console.log('onReady')}
                        onStart={() => console.log('onStart')}
                        onPlay={() => console.log('onPlay')}
                        onPause={() => console.log('onPlay')}
                        onBuffer={() => console.log('onPause')}
                        onSeek={e => console.log('onSeek', e)}
                        onEnded={() => console.log('onEnded')}
                        onError={e => console.log('onError', e)}
                        onProgress={this.handleProgress}
                        onDuration={this.handleDuration}
                        config={{
                            file: {
                                attributes: {
                                    poster,
                                    controls: true,
                                    controlsList: "nodownload nofullscreen noremoteplayback",

                                }
                            }
                        }}
                    />
                </div>

                {/* <section className={`more-video-wrap ${showMoreVideoSection ? '' : 'hide'}`}>
                    <div onClick={() => { this.setState({ showMoreVideoSection: false }) }} className="back-link"><LeftArrowIcon /></div>
                    <ul className="more-video-list">
                        {[1, 2, 3, 4].map(e => <li key={e} className="more-video">
                            <div className="img-wrap"><img src="https://em-generated-assets.s3-eu-west-1.amazonaws.com/2018/05/13/0ff81ccfe/image1024x768.jpg" alt="more-video" /></div>
                            <h4>2. Amawole</h4>
                            <p>African Lullabies is a collection of songs in various languages spoken around the African continent. These short songs show children the beauty of, not so, foreign languages</p>
                        </li>)}
                    </ul>
                </section> */}

                {/* <div className={`more-video-link-wrap ${showMoreVideoSection ? 'hide' : ''}`}>
                    <Link to="/" className="back-link"><LeftArrowIcon /></Link>
                    <div onClick={() => { this.setState({ showMoreVideoSection: true }) }}>Video</div>
                </div> */}

                {/* <ul className="playback-arrow-list d-flex">
                    {isBackword && <li className="backward">&lt;&lt;</li>}
                    {playbackRate !== DEFAULT_PLAYBACK_RATE && <li className="forward">&gt;&gt;</li>}
                </ul> */}

                <div className={`pause-wrap d-flex ${playing ? 'hide' : ''}`}>
                    <Link to={`/videos?categoryId=${this.categoryId}`} className="back-link"><LeftArrowIcon /></Link>
                    <div className="text-wrap">
                        <h3 className="heading">{this.selectedVideo.name}</h3>
                        <p className="description">{this.selectedVideo.description}</p>
                    </div>
                    {/* <ul className="key-codes">
                        {this.state.eventKeyCodes.map((e, index) => <li key={index}>KeyCode: {e}</li>)}
                    </ul> */}
                    <PlayIcon onClick={this.handlePlayPause} />
                </div>


            </main>
        )
    }
}

export default VideoPlayer