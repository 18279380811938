export default {
    VK_UP: 38,
    VK_RIGHT: 39,
    VK_DOWN: 40,
    VK_LEFT: 37,
    VK_ENTER: 13,
    VK_BACK_SPACE: 8,
    VK_PLAY: 415, //KEY_ONE
    VK_PAUSE: 19, // KEY_THREE
    VK_PLAY_PAUSE: 463, //KEY_DOT
    VK_STOP: 413, //KEY_ZERO
    VK_FAST_FWD: 417,
    VK_REWIND: 412,

    KEY_ONE: 35,
    KEY_THREE: 34,
    KEY_ZERO: 45,
    KEY_DOT: 46,
}
