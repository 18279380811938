import {
    BookmarkIcon,
    BallIcon,
    GroupIcon,
    TriangleIcon,
    GradeSchoolIcon,
    QALiveIcon,
} from "../components/Icons/Icons";

export default [
    {
        label: "Big School",
        image: GradeSchoolIcon,
        className: "big-school",
    },
    {
        label: "Learn",
        image: BookmarkIcon,
        className: "learner",
    },
    {
        label: "Play",
        image: BallIcon,
        className: "play",
    },
    {
        label: "Teach",
        image: GroupIcon,
        className: "teach",
    },
    {
        label: "Holiday Festival",
        image: TriangleIcon,
        className: "life",
    },
    {
        label: "Q and A live 2017",
        image: QALiveIcon,
        className: "qa-live",
    },
    // }, {
    //   label: 'Health',
    //   image: HeartIcon,
    //   className: 'health',
    // }, {
    //   label: 'Exam Questions 2017',
    //   image: DocumentIcon,
    //   className: 'exam',
    // }, {
    //   label: 'Second Chance Matric',
    //   image: HatIcon,
    //   className: 'matric',
    // }
];
