import React from 'react';
import { MindsetIcon } from '../Icons/Icons'
import './Header.scss'
import { Link } from 'react-router-dom';

export default function Header() {
    return <header>
        {/* <Link to="/" className="home-link" tabIndex={-1}><HomeIcon /><span className="home">Home</span></Link> */}
        <Link to="/" tabIndex={-1} className="mindset-text"><MindsetIcon /><span className="mindset">mindset</span></Link>
    </header>
}